<template>
    <Row class="sustainable" type="flex" justify="center">
        <Col :span="24" :md="18" :lg="18">
            <img src="@img/sustainable/banner.jpg" alt="">
        </Col>
        <Col :span="22" :md="18" :lg="18" class="text">
            <div>What is “sustainable fashion”?“Sustainable fashion can be defined as clothing, shoes and accessories that are manufactures, marketed and used in the most sustainable manner possible, taking into account both environmental and socio-economic aspects”-Green strategy</div>
            <br/>
            <br/>
            <div>As a consumer, there are things we can do to support sustainable fashion for a better future.</div>
        </Col>
        <Col :span="22" :md="18" :lg="18" class="image-text">
            <Row :class="[isMobile ? 'container-mobile' : 'container']">
                <Col :span="24" :md="10" class="image">
                    <img src="@img/sustainable/s-1.jpg" alt="">
                    <div>Fashion is the second largest polluting industry in the world.</div>
                </Col>
                <Col :span="24" :md="12" :class="[isMobile ? 'content-mobile' : 'content']">
                    <h3>GREEN MARK</h3>
                    <div>creating a mark called "green Mark" and giving new meaning to the logo.It's obvious on clothes, which can be deep-rooted in people's heart, so when you wear clothes with this mark, everyone will know that you are a decent person, and doing your best to protect the earth.</div>
                    <img src="@img/sustainable/s-5.png" style="width: 60px; margin: 1.5rem 0rem;" alt="">
                </Col>
            </Row>
        </Col>
        <Col :span="22" :md="18" :lg="18" class="image-text">
            <Row :class="[isMobile ? 'container-mobile' : 'container']">
                <Col :span="24" :md="10" class="image">
                    <img src="@img/sustainable/s-2.jpg" alt="">
                    <div>Fur manufacturing process is cruel to animals.</div>
                </Col>
                <Col :span="24" :md="12" :class="[isMobile ? 'content-mobile' : 'content']">
                    <h3>GREEN MARK FUR SERIES</h3>
                    <div>Green mark fur series that means it dont made by real fur; in contrast, it made by eco-friendly materials.</div>
                    <img src="@img/sustainable/s-6.png" style="width: 160px; margin: 1.5rem 0rem;" alt="">
                </Col>
            </Row>
        </Col>
        <Col :span="22" :md="18" :lg="18" class="sub-banner">
            <img src="@img/sustainable/s-8.png" alt="">
            <!-- <div>GREEN MARK SERIES</div> -->
        </Col>
        <Col :span="22" :md="18" :lg="18" class="image-text">
            <Row :class="[isMobile ? 'container-mobile' : 'container']">
                <Col :span="24" :md="12" class="image">
                    <img src="@img/sustainable/s-3.jpg" alt="">
                    <!-- <div>Fur manufacturing process is cruel to animals.</div> -->
                </Col>
                <Col :span="24" :md="12" :class="[isMobile ? 'content-mobile' : 'content']">
                    <h3>About Green Mark</h3>
                    <div style="margin-bottom: 1rem;">“Green Mark” symbolizes sustainable fashion</div>
                    <div>Any product with Green Mark means that it meets out stringent environmental protection material standards.</div>
                </Col>
            </Row>
        </Col>
        <Col :span="22" :md="18" :lg="18" class="image-text">
            <Row :class="[isMobile ? 'container-mobile' : 'container']">
                <Col :span="24" :md="12" class="image">
                    <img src="@img/sustainable/s-4.jpg" alt="">
                    <!-- <div>Fur manufacturing process is cruel to animals.</div> -->
                </Col>
                <Col :span="24" :md="12" :class="[isMobile ? 'content-mobile' : 'content']">
                    <img src="@img/sustainable/s-7.png" style="width: 60px; margin: 1.5rem 0;" alt="">
                    <h3>Green Rabbit Mark</h3>
                    <div style="margin-bottom: 1rem;">The production of rabbit fur is the largest in the fur market according to the research. We can convey how to avoid skinning a live animal and protect them. As long as your product is not made if real rabbit fur and matches our environmentally friendly material standards you can work with us to get the Green Rabbit Mark on your products.</div>
                    <div>Let people know that you are taking effort on our environment and the fur products you are wearing do not come from those cute bunnies.</div>
                </Col>
            </Row>
        </Col>
    </Row>
</template>

<script>
import common from '@M/common';

export default {
    name: 'Sustainable',
    mixins: [common],
    data() {
        return {
            isMobile: false
        }
    },
    mounted() {
        const mobileWidth = 768;

        this.isMobile =  window.innerWidth < mobileWidth

        window.addEventListener('resize', (e) => {
            this.isMobile = e.target.innerWidth < mobileWidth;
        });
    },
    beforeDestroy() {
        window.removeEventListener('resize', () => {});
    }
}
</script>

<style lang="scss">
.sustainable {
    flex-direction: column;
    align-items: center;
    color: #000;
    > div {
        max-width: 800px;
        margin-bottom: 2rem;

        &:first-child {
            margin-top: 5rem;
        }
        img {
            width: 100%;
            height: inherit;
        }
    }
    
    .text {
        line-height: 2rem;
        font-size: 13px;
    }

    .image-text {
        display: flex;
        margin-bottom: 6rem;
        > .container {
            display: flex;
            align-items: center;
        }

        > .container-mobile {
            display: block;
        }

        .image{
            > div {
                font-size: 13px;
            }
            
        }

        .content {
            padding: 3rem 0rem 3rem 4rem;
            font-size: 12px;

            h3 {
                margin-bottom: 1rem;
                font-size: 16px;
                font-family: Arial Black;
            }

        }

        .content-mobile {
            padding: 3rem 0;;
        }
        
    }

    .sub-banner {
        > div {
            position: absolute;
            top: 30%;
            font-family: Arial Black;
            font-size: 24px;
        }
    }
}
</style>